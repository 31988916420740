.card{
    min-height: 120px;
    border: none !important;
   /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important; */
    box-shadow: 0px 2px 20px -10px rgba(43, 43, 43, 0.10);
    border-radius: 20px !important;
    /* background: linear-gradient(27deg, rgba(255,255,255,1) 54%, rgba(107,104,255,0.053658963585434205) 42%); */
}
.card-body.all{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;


}
.all a{
    color: var(--primary-color);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none !important;
}
.heureMsg{
   
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color:#CDCDCD;
    padding-bottom:34px ;
     
}
.card-text{

font-family: 'Inter';
font-size: 16px !important;
font-weight: 600 !important;
line-height: 22px important;
letter-spacing: 0em !important;
text-align: left;

}