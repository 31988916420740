.bg-fond{
    background-color: #FAFAFA;
    box-shadow: inset 0 -11px 20px 0px rgba(0, 0, 0, 0.10) !important;
}
.d-title{
    color: var(--typo-color);
    font-family: 'Integral-CF';
    font-size: 36px !important;
    font-weight: 600 !important;
    line-height: 43px !important;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0 !important;
    width: fit-content;
   
    
}
.border-title::after{
    content: "";
    display: block;
    width: 100%;
    height: 9px;
    background-color: var(--press-color);
    margin-top: -8px;
}
.p-40{
    padding:40px !important;
  }
  .text-venir{
    color :#345AE1;
    font-family: 'Noto-Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .m-vh-100{
    min-height: 100vh;
  }
  .warning{
    color: #FFA115 !important;
    font-family: 'Noto-Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  }
  .success{
    color: #11AB58 !important;
    font-family: 'Noto-Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  }
  .w-fitcontent{
    width: fit-content;
  }
  @media screen and (max-width:768px) {
    .w-100-xs{
      width:100%;
      margin-top:10px;
    } 
  }
  @media screen and (max-width:426px) {
    .p-40{
      padding: 10px !important;
    }
    .event header{
      justify-content: normal !important;
    }
    .right.resp-btn{
      float: none !important;
      margin-top: 10px;
    }
    
  }
  .bread-item{
    color: var(--primary-color);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .retour-link{
    text-decoration: none;
    color: var(--primary-color);
    margin-bottom: 25px;
  }
  .retour-link:hover{
    text-decoration: none;
    color: var(--secondary-color);
    margin-bottom: 25px;
  }
  .pt-26{
    padding-top: 26px;
  }
 