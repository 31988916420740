.modal-up{
    display: flex !important;
    align-items: center;
}
.modal-content{
    border: none !important;
    border-radius: 0 !important;
    padding: 30px !important;
}
.modal-body{
    padding: 0 !important;
}
.modal-body .btn{
    padding: 0px !important;
}
.modal-header{
    border-bottom: none !important;
}
.modal-footer{
    border-top: none !important;
}
.modal-dialog{
    max-width: 634px !important;
    width: 100% !important;
}
.step-counter{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color:var(--primary-color);
}
.radio-anim{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px;
    text-align: center;
    border-radius: 4px;
    
}
.radio-anim input{
    display: none;
}
.checked-radio{
    border: 1px solid var(--primary-color);
}
.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
  }
  .sub-title{

font-family: 'Inter';
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
padding: 5px 0 10px 0;

  }

