
  .bg-sideBar{
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  .ml-15{
    margin-right: 12px;
  }
  .nav{
    width: 100%;
  }
  .nav-item.active{
    background-color: #6B68FF;
    width: 90%; 
    border-radius: 0px 4px 4px 0px;
  }
  .nav-item.active svg{
    color: #fff;
  }
  .pl-30{
    padding-left: 30px;
  }
  .nav-item{
    padding: 15px 30px;
    text-decoration: none;
    color: var(--typo-color);

font-family: 'Jost';
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 120% */
letter-spacing: -0.4px;
  }
  .nav-item.active span{
    color:#fff;
  }
  .shadow-sidebar{

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10) !important;
  }
  .px-30 {
        padding: 19px 30px;
  }
  .nav-item h5{
    font-family: "Futura";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .dark-txt{

font-family: "Futura";
font-size: 26px;
font-weight: 500;
line-height: 32px;
letter-spacing: -0.02em;
text-align: left;
color:#2B2B2B;
padding: 19px 30px;

  }
 .px-30{
    padding-left: 30px;
 }
 .logo{
    padding-top: 19px;
    padding-left: 30px;
    padding-bottom: 39px;
 }
.nav-img{
    width:20px;
}
.close-sidebar{
  flex: 0 1 !important;
  width: auto !important;
}
.none{
  display: none !important;
}
.logout{
  position: fixed;
  bottom: 10%;
}
@media screen and (max-width:1009px) {
  .nav-item{
    padding: 10px !important;
    text-decoration: none;
  }
  .ml-15{
    margin-right: 2px !important;
  }
  .nav-item.active{
    background-color: #6B68FF;
    width: 95% !important; 
    border-radius: 0px 4px 4px 0px;
  }
}