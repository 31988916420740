.login{
    background-color:#6B68FF ;
    height: 100vh;
    background-image: url('../img/Frame.png');
    background-repeat: repeat-x;
    background-position: bottom;
}
.form-login{
    text-align: left; 
    background-color: #fff;
    margin: auto;
    padding: 100px;
    /* width: 40%; */
    position: absolute; /* sets the position of the block to absolute */
	top: 50%; /* sets the block 50% from the top of the page */
	left: 50%; /* sets the block 50% from the left of the page */
	transform: translate(-50%, -50%); /* centers the block both horizontally and vertically */
}	
.title-login{
    font-family: 'Integral-CF';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;   
    color: #2B2B2B;
    margin-bottom: 30px;
}	
label{

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;  
    color: #2B2B2B; 
    margin-bottom: 6px;
}
.login .btn{
    padding: 10px auto;
    margin-top: 20px;
    width:100%;
    background: #6B68FF;
    border-radius: 4px;
}
.link{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
text-decoration: none;
color: #6B68FF;
margin-top: 10px;
}
.float-right{
    text-align: right;
}
.title-reset{
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height, or 119% */
    
    letter-spacing: -0.02em;
    
    /* Typo */
    
    color: #2B2B2B; 
}

.password-field {
    display: flex;
    flex-direction: column;
 
  }
  
  .password-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .password-input-container input {
    flex-grow: 1;
  }
  
  .password-input-container .password-icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
  