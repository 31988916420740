@font-face {
  font-family: 'Integral-CF';
  src: url('./assets/fonts/IntegralCF-DemiBold.ttf') 
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') 
}
@font-face {
  font-family: 'Noto-Sans';
  src: url('./assets/fonts/NotoSans-Regular.ttf') 
}
@font-face {
  font-family: 'Jost';
  src: url('./assets/fonts/Jost-Regular.ttf') 
}
@font-face {
  font-family: 'Jost-Medium';
  src: url('./assets/fonts/Jost-Medium.ttf') 
}
/********* GENERAL STYLE***********/
:root {
  --primary-color:#6B68FF;
  --secondary-color:#8986FF;
  --press-color:#B5B4FF;
  --bg-color:#FAFAFA;
  --typo-color:#2B2B2B;
}
.pt-10{
  padding-top: 10px;
}
.datatable-all span{
  opacity: 1 !important;
  padding: 0 10px;
}
.KUTzo {
  opacity: 1 !important;
  /* how to change icon trie */
  /* background: url('./assets/img/svg/trie.svg') !important; */
}
.btn-primary-up{
  background-color: var(--primary-color) !important;
  text-decoration: none !important;
  color: #fff !important;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-family: 'Inter';
  border:none;
  border-radius: 4px;
}
.btn-primary-up:hover{
  background-color: var(--secondary-color) !important;
  
}
.btn-primary-up:focus{
  background-color: var(--press-color) !important;
}
.btn-secondary-up{
  border-color: var(--primary-color) !important;
  border: 1px solid;
  color: var(--primary-color)!important;
  background-color: #fff !important;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-family: 'Inter';
  border-radius: 4px;
}
.btn-secondary-up:hover{
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}
.btn-secondary-up:focus{
  border-color: var(--press-color);
  color: var(--press-color);
}
.link{
  color: var(--primary-color) ;
  background-color: transparent;
  border: none;
 
}
.link:hover{
  color: var(--secondary-color) ;
  background-color: transparent;
  border: none;
 
}
a{
color: var(--primary-color) ;
}
a:hover{
  color:var(--secondary-color) ;
}
a:focus{
  color:var(--press-color) ;
}
.text-right{
  text-align: right;
}
h1{
  font-family:'Integral-CF' !important ;
font-size: 36px !important;
font-weight: 600 !important;
line-height: 43px  !important;
letter-spacing: 0em;
text-align: left;

}
h2{
  font-family: 'Jost-Medium' !important;
font-size: 32px !important;
font-weight: 500 !important;
line-height: 38px !important;
letter-spacing: -0.02em;
text-align: left;

}
h3{
  font-family: 'Jost-Medium' !important;
  font-size: 26px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: -0.02em;
  text-align: left;
}
h4{
  font-family: 'Jost' !important;
font-size: 22px !important;
font-weight: 500!important;
line-height: 28px !important;
letter-spacing: -0.02em;
text-align: left;

}
h5{
  font-family: 'Jost'!important;
font-size: 20px !important;
font-weight: 700 !important;
line-height: 24px !important;
letter-spacing: -0.02em;
text-align: left;

}
p{
  font-family: 'Noto-Sans';
font-size: 16px !important;
font-weight: 400 !important;
line-height: 22px !important;
letter-spacing: 0em;
text-align: left;

}
label{
  font-family: 'Inter';
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}
body{
  font-family: 'Inter' !important;
}
.right{
  float:right;
}
.m-40{
  margin:40px !important;
}
.space-72{
  height: 72px;
}
.w-30{
  width: 30% !important;
}
.btn-group .btn-link{
  padding: 5px !important;
}
#search {
  padding: 9px 10px;
  background: url(./assets/img/svg/search.svg) no-repeat right center;
  background-position: 95%;
  border: 1px solid var(--typo-color);
  background-color: #fff;
  border-radius: 4px;
}
input::placeholder{
  color: #D5D5D5 !important;

/* Current text */
font-family: 'Noto-Sans'  !important;
font-size: 16px   !important;
font-style: normal   !important;
font-weight: 400   !important;
line-height: 22px   !important; /* 137.5% */
}
input[type="text"],input[type="number"],input[type="url"],input[type="email"],input[type="date"],input[type="time"]{
  border: 1px solid var(--typo-color) !important;
  background-color: #fff !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  
  background: url(./assets/img/svg/calendar.svg) no-repeat;
  background-size: 97%;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  
  background: url(./assets/img/svg/clock.svg) no-repeat;
  background-size: 97%;
  box-shadow: none !important;
}
input::placeholder{
  color:#D5D5D5 ;
  /* font-family: 'Noto Sans'; */
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
   box-shadow: none !important;
}
.form-select, form-select:focus{
  border: 1px solid var(--typo-color) !important;
    background-color: #fff !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    background-image: url('./assets/img/svg//Vector-select.svg') !important;
}
input[type="checkbox"]:checked{
  /* -moz-appearance:none;
-webkit-appearance:none;
-o-appearance:none; */
    background-color: #6b68ff!important;
    border-color: #6b68ff!important;
}

.login input {
  /* Blanc */

background: #FFFFFF;
/* Typo */

border: 1px solid #2B2B2B;
border-radius: 4px;
}
.rdt_Pagination{
  background-color: transparent !important;
  border : none !important;
}
.rdt_TableHeadRow {
  border-bottom: none !important;
}
.pt-30{
  padding-top: 30px;
}
.pt-20{
  padding-top: 20px;
}
.pr-20{
  padding-right: 20px;
}
.mr-20{
  margin-right: 20px;
}
.typo{
color:var(--typo-color);
font-family: 'Noto-Sans';
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}
.event header, .animations header{
  background-color: transparent !important;
}
button:disabled{
  background: #EAEAEA !important;
border-radius: 4px !important;
}
.text-warning{
  color: #FFA115 !important;
  font-family: 'Noto-Sans' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 16px !important;
line-height: 22px !important;
}
.mb-10{
 margin-bottom: 10px;
}
.mt-20{
  margin-top: 20px;
}
.pl-15{
  padding-left: 15px;
}
.pl-13{
  padding-left: 13px;
}
.mb-30{
  margin-bottom: 30px;
}
a {
  word-wrap: break-word;
}
.text-success{
 
font-family: 'Noto-Sans';
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color:#11AB58 !important;

}
.rdt_TableHeadRow{
  background-color: #FAFAFA !important;
  width: 98%;
  margin: 10px;
}
