.footer{
    background-color: #EFEFEF;
    padding: 30px;
    text-align: center;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: -webkit-fill-available !important;
}
.footer a{
    color :var(--typo-color) !important;
    text-decoration: none;
    margin:10px;
    font-family: 'Noto-Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}
.item-footer{
    padding-right: 10px;
    border-right: 1px solid #DBDBDB;
}