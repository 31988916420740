.bg-stadium{
    background-color: #F1F1F1;
    padding: 50px;
}
.mt-30{
    margin-top: 30px !important;
}
/****stadium style**/

.st0{fill:#dae2ee}.st1{fill:#2b2b2b}.st3{fill:#fff}
    
.st0 {
   
    cursor: pointer;
}
.st0:focus{
    fill:#7CC5FF;
}

.m212{fill:#6b9c63;}

.zone-list:after {
    content: " - ";
    width: 20px;
    color: black;
    text-align: center;
}
.zone-list:last-child:after{
    content: none;
}
path {
    cursor: pointer;
}
.btn__container{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.mb-20{
    margin-bottom: 20px;
}
.mt-14{
    margin-top: 14px;
}
.form-check-input:checked{
    background-color: #6B68FF !important;
    border-color: #6B68FF !important;
}
.form-check-input{
    border:1px solid #6B68FF !important;
}
.checkbox-up{
   width: 18px !important;
   height: 18px !important;
   border:1px solid #6B68FF !important;
   border: #6B68FF;
   border-bottom: 5px !important;
}
.active-bread{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);

}